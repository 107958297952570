.bear-bull-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  padding: 1rem 2rem;
  background-color: #0e0d0d;
  border-bottom: 2px solid #a2a0a1;
  transform: translateY(-150%);
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  @include media("<=480px") {
    padding: 1.5rem 0.5rem;
    border-bottom: 2px solid #a2a0a1;
  }
  &.active {
    transform: translateY(0%);
  }
  .buttons-wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;

    @include media("<=480px") {
      gap: 0.5rem;
      z-index: 10;
    }

    .bear-bull-btn {
      border: 2px solid grey;
      background-color: #00000090;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      cursor: $cursor-Pointer;
      transition: background-color 0.1s ease-in-out;
      @include media("<=480px") {
        width: 40px;
        height: 40px;
        padding: 0;
      }
      &:hover {
        background-color: #8a8a8ab0;
      }
      img {
        width: 95%;
        @include media("<=480px") {
          width: 75%;
        }
      }
    }

    .landing-whitelist-quests-wrapper {
      color: white;
      font-size: 20px;
      position: relative;
      z-index: 100;

      &:hover {
        .landing-whitelist-pop-wrapper {
          visibility: visible;
          transform: translateY(0px) translateX(-50%);
          opacity: 1;
        }
      }
      .landing-whitelist-pop-wrapper {
        height: 7.5rem;
        width: 100%;
        position: absolute;
        visibility: hidden;
        left: 50%;
        opacity: 0;
        transform: translateY(-10px) translateX(-50%);
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

        .whitelist-quests-pop {
          background-color: #151515;
          border-radius: 10px;
          top: 0.5rem;
          border: 2px solid #a2a0a1;
          padding: 1rem 1rem;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          gap: 1rem;
          box-shadow: 0px 0px 10px 0px #000000;

          button {
            background-color: #222222;
            color: #ffa556;
            font-size: 16px;
            line-height: 1;
            &:hover {
              background-color: #606060;
            }
          }
        }
      }
    }
  }

  .bear-bull-nav-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 25rem;
    height: 8.75rem;
    @include media("<=480px") {
      display: none;
    }

    .nav-svg {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
    }

    .bear-bull-nav-logo {
      width: 100px;
      z-index: 1;
      position: absolute;
      left: 50%;
      top: 40px;
      transform: translateX(-50%);
    }
  }

  .bear-bull-nav-right-side {
    display: flex;
    gap: 0.5rem;
    @include media("<=950px") {
      z-index: 10;
    }
    .volume-button-wrapper {
      display: flex;
      align-items: center;
      padding: 0.65rem 1rem;
      cursor: $cursor-Pointer;
      border: 2px solid #3e3e3e;
      border-radius: 7.5px;
      background-color: black;
      svg {
        path {
          fill: #f27921;
        }
      }
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      @include media("<=480px") {
        padding: 0;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.bear-bull-page {
  width: 100vw;
  overflow: hidden;
  @include media("<=480px") {
    overflow-x: hidden;
  }

  .panel {
    min-height: 100vh;
    overflow: hidden;
    @include media("<=480px") {
      min-height: auto;
      padding: 5rem 0;
    }
  }

  .background-element {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-image: url("../../../public/assets/bear-bull-game/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
    @include media("<=1650px") {
      background-size: 110%;
    }
    @include media("<=480px") {
      background-size: cover;
    }
  }

  .bear-bull-type-section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    @include media("<=1800px") {
      gap: 2rem;
    }

    .bear-bull-type-help-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      position: absolute;
      bottom: 10%;
      color: #ffa556;
      font-weight: 100;
      @include media("<=480px") {
        text-align: center;
        line-height: 1.1;
        bottom: 1.5rem;
      }
      svg {
        @include media("<=480px") {
          display: none;
        }
      }
      p {
        position: relative;
        top: 0.05rem;
      }
    }
    .bear-types-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50vh;
      z-index: 10;

      @include media("<=1550px") {
        left: 5rem;
      }
      @include media("<=480px") {
        position: absolute;
      }

      .types-left-side {
        position: relative;
        scale: 2.5;
        transform: translateX(-10px);
        @include media("<=950px") {
          transform: translateX(-25px);
          scale: 1.5;
        }

        @include media("<=480px") {
          transform: translateX(-125px);
          scale: 1;
        }
        .noobie-bear-btn,
        .fighter-bear-btn,
        .hostage-bear-btn,
        .martyr-bear-btn {
          cursor: $cursor-Pointer;
          &.active {
            path {
              fill: #ffa556;
            }
          }
          path {
            transition: fill 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
          &:hover {
            path {
              fill: white;
            }
          }
        }
      }
      .bear-buttons-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;

        .bear-type-button {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          background-color: transparent;
          border: none;
          font-family: $font-superStar;
          font-size: 36px;
          gap: 1rem;
          width: max-content;
          color: #797776;
          cursor: $cursor-Pointer;
          position: relative;
          &.bear-btn-0 {
            left: -3rem;
          }
          &.bear-btn-1 {
            left: -11rem;
          }
          &.bear-btn-2 {
            left: -11.5rem;
          }
          &.bear-btn-3 {
            left: -3rem;
          }

          &.active {
            color: #e9581d;
          }
          img {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            object-fit: cover;
            padding: 0.5rem;
          }
        }
      }
    }

    .bear-bull-type-center {
      position: relative;
      z-index: 1;
      top: -4rem;
      @include media("<=480px") {
        top: -15rem;
      }
      .center-img {
        width: 500px;
        @include media("<=480px") {
          width: 250px;
        }
      }

      .bear-bull-type-center-text {
        position: absolute;
        font-family: $font-superStar;
        font-size: 72px;
        color: white;
        left: 50%;
        top: 89%;
        transform: translate(-50%, -50%);
        @include media("<=950px") {
          font-size: 28px;
        }
      }

      .bear-bull-type-paragraph {
        position: absolute;
        max-width: 450px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: $font-superStar;
        line-height: 1.1;
        bottom: -8rem;
        font-size: 24px;
        color: white;
        @include media("<=950px") {
          font-size: 18px;
          max-width: 500px;
          font-weight: 100;
        }
        @include media("<=480px") {
          font-size: 16px;
          max-width: none;
          width: 200px;
          bottom: -400px;
        }
      }
    }
    .bull-types-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50vh;
      z-index: 10;

      @include media("<=1550px") {
        right: 5rem;
      }

      @include media("<=480px") {
        position: absolute;
      }

      .types-right-side {
        scale: 2.5;
        position: relative;
        transform: translateX(-37.5px);
        @include media("<=950px") {
          transform: translateX(-25px);
          scale: 1.5;
        }

        @include media("<=480px") {
          transform: translateX(60px);
          scale: 1;
        }

        .noobie-bull-btn,
        .fighter-bull-btn,
        .hostage-bull-btn,
        .martyr-bull-btn {
          cursor: $cursor-Pointer;
          &.active {
            path {
              fill: #ffa556;
            }
          }
          path {
            transition: fill 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
          &:hover {
            path {
              fill: white;
            }
          }
        }
      }
      .bull-buttons-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;

        .bull-type-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: transparent;
          border: none;
          font-family: $font-superStar;
          font-size: 36px;
          gap: 1rem;
          width: max-content;
          color: #797776;
          cursor: $cursor-Pointer;
          position: relative;
          &.bull-btn-0 {
            left: 3.5rem;
          }
          &.bull-btn-1 {
            left: 11rem;
          }
          &.bull-btn-2 {
            left: 11.5rem;
          }
          &.bull-btn-3 {
            left: 4rem;
          }

          &.active {
            color: #e9581d;
          }
          img {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            object-fit: cover;
            padding: 0.5rem;
          }
        }
      }
    }
  }

  .bear-bull-allowlist {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding-top: 2.5rem;
    @include media("<=1450px") {
      padding-top: 6.5rem;
    }
    img {
      width: 90%;
      object-fit: cover;
      margin: 0 auto;
      cursor: $cursor-Pointer;
    }

    .bear-bull-game-cta-btn-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 15;
      opacity: 1;
      gap: 0.5rem;
      margin-top: 2rem;
      @include media("<=950px") {
        flex-direction: column;
        bottom: 1rem;
        gap: 0.25rem;
      }
      @include media("<=480px") {
        bottom: 2rem;
      }

      .cta-text-bearbull {
        font-family: $font-superStar;
        font-size: 36px;
        color: white;
        @include media("<=950px") {
          font-size: 24px;
        }
        @include media("<=480px") {
          font-size: 1.5rem;
          text-align: center;
          line-height: 1.05;
        }
      }

      .bearbull-input-wrapper {
        display: flex;
        gap: 1rem;
        @include media("<=480px") {
          flex-direction: column;
          gap: 0.5rem;
        }
        .home-email-input-wrapper {
          .email-input {
            width: 20rem;
            background-color: #222222;
            border: none;
            outline: none;
            color: white;
            padding: 0.5rem 0.5rem;
            font-size: 20px;
            border: 2px solid #3e3e3e;
            border-radius: 7.5px;
            transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
            &:focus {
              background-color: #333333;
            }
          }
        }
        .home-cta-btn {
          background-color: #222222;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.3rem 1.5rem;
          font-size: 24px;
          color: #f27921;
          cursor: $cursor-Pointer;
          border: 2px solid #3e3e3e;
          border-radius: 7.5px;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          font-family: $font-superStar;

          .loader {
            width: 37.5px;
            aspect-ratio: 1;
            border-radius: 50%;
            border: 8px solid #0000;
            border-right-color: #ffa50097;
            position: relative;
            animation: l24 1s infinite linear;
            &:before,
            &:after {
              content: "";
              position: absolute;
              inset: -8px;
              border-radius: 50%;
              border: inherit;
              animation: inherit;
              animation-duration: 2s;
            }
            &:after {
              animation-duration: 4s;
            }
            @keyframes l24 {
              100% {
                transform: rotate(1turn);
              }
            }
          }

          &:hover {
            background-color: #343434;
          }
        }
      }
    }
  }

  .bear-bull-team {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding-top: 4rem;
    padding-bottom: 3rem;
    .bear-bull-title {
      width: 100%;
      text-align: center;
      font-size: 92px;
      color: black;
      font-family: $font-superStar;
      position: relative;
      -webkit-text-stroke: 2px white;
      top: -2rem;
      @include media("<=950px") {
        font-size: 72px;
        -webkit-text-stroke: 1.5px white;
        top: -3rem;
      }
      @include media("<=480px") {
        top: -5rem;
        font-size: 36px;
        -webkit-text-stroke: 1px white;
      }
    }

    .cards-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -3.5rem;

      @include media("<=950px") {
        gap: 2rem 1rem;
        flex-wrap: wrap;
      }

      @include media("<=480px") {
        padding-bottom: 4rem;
      }

      .team-card-wrapper {
        position: relative;
        border-radius: 20px;
        border: 4px solid #474747;
        width: 250px;
        @include media("<=1200px") {
          width: 200px;
        }
        &:not(:last-child) {
          margin-right: 1rem;
        }
        @include media("<=1550px") {
          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
        .team-card-top {
          position: relative;
          overflow: hidden;
          .member-title-wrapper {
            position: absolute;
            width: 100%;
            justify-content: center;
            display: flex;
            height: 3rem;
            border-radius: 16px 16px 0 0;
            overflow: hidden;

            .team-card-curve {
              position: absolute;
              top: 0.25rem;
              z-index: 10;
              transform: scaleX(1.75) scaleY(1.5);
            }
            .member-title {
              z-index: 11;
              position: absolute;
              top: 0;
              text-align: center;
              color: white;
              font-family: $font-superStar;
              font-size: 24px;
              @include media("<=1200px") {
                font-size: 20px;
                top: 0.25rem;
              }
            }
          }
          .member-image {
            border-radius: 19px 19px 0 0;
            width: 250px;
            height: 300px;
            object-fit: cover;
            @include media("<=950px") {
              height: 200px;
            }
          }
        }

        .team-card-bottom {
          position: relative;
          height: 200px;
          background-color: black;
          border-radius: 0 0 20px 20px;

          @include media("<=1550px") {
            height: 150px;
          }
          .member-team-name-wrapper {
            position: absolute;
            top: -1.5rem;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            padding: 0.1rem 0;
            background-color: #000;
            text-align: center;
            border-radius: 30px;
            border: 4px solid #474747;
            color: white;
            font-family: $font-superStar;
            font-size: 24px;
            @include media("<=1200px") {
              font-size: 20px;
            }
          }

          .member-bio {
            text-align: center;
            padding: 3rem 2rem 0.5rem 2rem;
            font-size: 20px;
            line-height: 1.05;
            font-family: $font-superStar;
            color: white;
            @include media("<=1550px") {
              font-size: 18px;
              padding: 2rem 1rem 0.5rem 1rem;
            }
            @include media("<=1200px") {
              padding: 2rem 1rem 0.5rem 1rem;
              font-size: 16px;
            }
          }
        }

        .member-social-btn {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -1.75rem;
          width: 50px;
          height: 50px;
          @include media("<=1200px") {
            width: 40px;
            height: 40px;
            bottom: -1.25rem;
          }
          &.disabled {
            img {
              cursor: $cursor-Default;
            }
            &:hover {
              img {
                filter: none;
              }
            }
          }
          img {
            transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
            cursor: $cursor-Pointer;
          }
          &:hover {
            img {
              filter: invert(1);
            }
          }
        }
      }
    }

    .coin-border-wrapper {
      position: absolute;
      bottom: 3rem;
      left: 50%;
      transform: translateX(-50%);
      img {
        top: -3.5rem;
        width: 100px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .bear-bull-roadmap {
    width: 100%;
    padding: 6rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .tools {
      position: absolute;
      display: flex;
      gap: 0.5rem;
      z-index: 1;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      button {
        width: max-content;
        background-color: transparent;
        border: none;
        outline: none;
        color: white;
      }
    }
    .bear-bull-roadmap-img {
      width: 70%;
      position: relative;
      top: 2rem;
      @include media("<=1450px") {
        width: 80%;
      }
      @include media("<=950px") {
        width: 100%;
      }
    }
  }

  .bear-bull-ecosystem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .bear-bull-ecosystem-title-wrapper {
      width: 100%;
      text-align: center;
      font-size: 72px;
      font-family: $font-superStar;
      position: relative;
      color: white;
      display: flex;
      justify-content: center;
      padding-top: 4rem;
      @include media("<=950px") {
        padding-top: 0;
      }
      .ecosystem-title-bullbear {
        display: flex;
        position: relative;

        @include media("<=480px") {
          font-size: 36px;
          gap: 1.5rem;
        }
      }
    }

    .bearbull-ecosystem-cards-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 1rem 0;
      gap: 2rem;

      @include media("<=950px") {
        gap: 0;
        padding-top: 0;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      @include media("<=480px") {
        flex-direction: column;
        gap: 1rem;
      }

      .ecosystem-card {
        width: 400px;
        font-family: $font-superStar;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include media("<=1200px") {
          width: 350px;
        }
        @include media("<=950px") {
          align-content: center;
        }

        .ecosystem-card-top {
          overflow: hidden;
          position: relative;
          width: 400px;
          height: 500px;
          border: 2px solid #474747;
          border-radius: 20px;
          transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
          @include media("<=1550px") {
            width: 350px;
          }
          @include media("<=1440px") {
            height: 350px;
          }
          @include media("<=1200px") {
            width: 300px;
          }
          @include media("<=950px") {
            width: 250px;
            height: 350px;
          }
          &:hover {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
              rgb(51, 51, 51) 0px 0px 0px 4px;
          }
          .card-title-wrapper {
            position: absolute;
            text-align: center;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .ecosystem-curve {
              width: 100%;
              position: absolute;
              top: -1.5rem;
              @include media("<=950px") {
                top: -1.6rem;
              }
            }

            .ecosystem-card-title {
              font-family: $font-superStar;
              font-size: 38px;
              color: white;
              z-index: 2;
              position: relative;
              top: -0.13rem;
              @include media("<=1200px") {
                font-size: 32px;
                top: 0;
              }
              @include media("<=950px") {
                font-size: 28px;
                top: -0.15rem;
              }
            }
            .card-coin {
              width: 60px;
              height: 60px;
              position: absolute;
              right: 1rem;
              top: -0.4rem;
              z-index: 3;
              @include media("<=1200px") {
                width: 40px;
                height: 40px;
                top: 0;
              }
              @include media("<=950px") {
                width: 35px;
                height: 35px;
                top: 0rem;
                right: 0.5rem;
              }
            }
            .background-color-element {
              position: absolute;
              width: 100%;
              height: 30px;
              top: 0;
              background-color: #0e0d0d;
              border-radius: 20px 20px 0 0;
              z-index: 1;
            }
            .card-title {
              font-size: 36px;
              color: white;
              border-bottom: 3px solid #474747;
              background-color: #0e0d0d;
              padding: 0.45rem 1rem;
              z-index: 2;
              @include media("<=480px") {
                font-size: 24px;
                padding: 0.4rem 0.5rem;
                border-bottom: 2px solid #474747;
              }
              &::before {
                content: "";
                position: absolute;
                width: 30px;
                // height: 32px;
                padding-bottom: 29.5px;
                border-bottom: 2px solid #474747;
                background-color: #0e0d0d;
                top: 0;
                left: 0;
                z-index: 0;
                border-radius: 20px 0 0 0;
              }
              &::after {
                content: "";
                position: absolute;
                width: 30px;
                // height: 32px;
                padding-bottom: 29.5px;
                border-bottom: 2px solid #474747;
                background-color: #0e0d0d;
                top: 0;
                right: 0;
                z-index: 0;
                border-radius: 0 20px 0 0;
              }
              .card-curve {
                position: absolute;
                width: 50px;
                height: 50px;
                @include media("<=480px") {
                  width: 50px;
                  height: 50px;
                }
                path:first-child {
                  fill: #0e0d0d;
                }
                path:nth-child(2) {
                  stroke: #474747;
                  stroke-width: 4px;
                  @include media("<=480px") {
                    stroke-width: 5px;
                  }
                }
                &.right {
                  right: 3.25rem;
                  top: 1.6rem;
                  transform: scaleX(2);

                  @include media("<=480px") {
                    transform: scaleX(2) scaleY(0.5);
                    top: 0.9rem;
                    right: 2.3rem;
                  }
                }
                &.left {
                  left: 3.25rem;
                  top: 1.6rem;
                  transform: scaleX(-2);
                  @include media("<=480px") {
                    transform: scaleX(-2) scaleY(0.5);
                    top: 0.9rem;
                    left: 2.5rem;
                  }
                }
              }
            }
          }

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 18px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 18px;
          }
        }
        &:nth-child(2) {
          width: 420px;
        }

        .ecosystem-card-description {
          padding-top: 2rem;

          font-size: 24px;
          color: white;
          line-height: 1.1;
          text-align: center;
          @include media("<=1200px") {
            font-size: 20px;
            padding: 1.5rem;
          }
        }
      }
    }
  }
}

.bear-bull-choose {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 20;
  .header-top-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 30;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    @include media("<=950px") {
      padding: 0 0.5rem;
    }

    .buttons-wrapper {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      padding-top: 1rem;
      z-index: 23;
      @include media("<=950px") {
        gap: 0.25rem;
      }
      .bear-bull-btn {
        border: 2px solid grey;
        background-color: #00000090;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        cursor: $cursor-Pointer;
        transition: background-color 0.1s ease-in-out;
        &:hover {
          background-color: #000000b0;
        }
        @include media("<=480px") {
          padding: 0;
        }

        img {
          width: 47.5px;
          @include media("<=480px") {
            width: 30px;
          }
        }
      }

      .landing-whitelist-quests-wrapper {
        color: white;
        font-size: 20px;
        position: relative;
        z-index: 100;
        cursor: $cursor-Pointer;

        &:hover {
          .landing-whitelist-pop-wrapper {
            visibility: visible;
            transform: translateY(0px) translateX(-50%);
            opacity: 1;
          }
        }
        .landing-whitelist-pop-wrapper {
          height: 7.5rem;
          width: 100%;
          position: absolute;
          visibility: hidden;
          left: 50%;
          opacity: 0;
          transform: translateY(-10px) translateX(-50%);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

          .whitelist-quests-pop {
            background-color: #151515;
            border-radius: 10px;
            top: 0.5rem;
            border: 2px solid #a2a0a1;
            padding: 1rem 1rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            gap: 1rem;
            box-shadow: 0px 0px 10px 0px #000000;

            button {
              background-color: #222222;
              color: #ffa556;
              font-size: 16px;
              line-height: 1;
              &:hover {
                background-color: #606060;
              }
            }
          }
        }
      }
    }

    .choose-your-side-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -2.75rem;
      width: 45rem;
      @include media("<=950px") {
        width: 30rem;
        top: -3.3rem;
      }
      .choose-your-side-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        top: 0;
        position: absolute;
        width: 100%;
        height: 10rem;
        clip-path: inset(0 0 22% 0);
        @include media("<=1200px") {
          width: 90%;
          top: -0.2rem;
        }
        @include media("<=950px") {
          width: 30rem;
        }
        @include media("<=480px") {
          display: none;
        }

        .choose-your-side-text {
          font-family: $font-superStar;
          font-size: 36px;
          color: white;
          z-index: 1;
          @include media("<=950px") {
            font-size: 24px;
          }
          @include media("<=480px") {
            font-size: 1.5rem;
          }
        }

        .choose-your-side-svg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          // @include media("<=1200px") {
          //   width: 90%;
          //   height: 90%;
          // }
        }
      }
    }
    .bear-bull-nav-right-side {
      display: flex;
      gap: 0.5rem;
      padding-top: 1rem;
      .volume-button-wrapper {
        display: flex;
        align-items: center;
        padding: 0.65rem 1rem;
        cursor: $cursor-Pointer;
        border: 2px solid #3e3e3e;
        border-radius: 7.5px;
        background-color: #000000;
        svg {
          path {
            fill: #f27921;
          }
        }
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        @include media("<=950px") {
          position: absolute;
          top: 4.5rem;
          right: 8px;
        }
        @include media("<=480px") {
          top: 0.3rem;
          right: auto;
          position: relative;
          width: 40px;
          height: 40px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 20px;
            height: 20px;
          }
        }
        &:hover {
          background-color: #000;
        }
      }
      .connect-wallet-bear-bull-game {
        display: flex;
        align-items: center;
        justify-content: center;
        @include media("<=950px") {
          // transform: translateX(-15px);
        }
      }
    }
  }

  .bear-bull-choose-sky {
    background-image: url("../../../public/assets/bear-bull-game/landing-sky.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .bear-bull-riverside,
  .clouds-left,
  .clouds-right,
  .bear-bull-cliffright,
  .bear-bull-cliffleft,
  .bear-bull-castleleft,
  .bear-bull-castleright {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    will-change: transform, scale;
  }

  .bear-bull-riverside {
    scale: 1.2;
    z-index: 2;
    background-image: url("../../../public/assets/bear-bull-game/landing-riverside.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @include media("<=950px") {
      scale: 3;
    }
  }

  .clouds-left {
    background-image: url("../../../public/assets/bear-bull-game/cloud-left.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center left;
    scale: 1.25;
    z-index: 1;
    @include media("<=950px") {
      background-position: center center;
    }
  }

  .clouds-right {
    background-image: url("../../../public/assets/bear-bull-game/cloud-right.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    z-index: 1;
    scale: 1.25;
    @include media("<=950px") {
      background-position: center center;
    }
  }

  .bear-bull-castleleft {
    background-image: url("../../../public/assets/bear-bull-game/castle-left.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    z-index: 1;

    @include media("<=950px") {
      display: none;
    }
  }

  .bear-bull-castleright {
    z-index: 1;
    background-image: url("../../../public/assets/bear-bull-game/castle-right.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    @include media("<=950px") {
      display: none;
    }
  }

  .bear-bull-cliffright {
    background-image: url("../../../public/assets/bear-bull-game/landing-cliffright.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
    @include media("<=950px") {
      left: auto;
      right: -25rem;
      background-size: cover;
      background-position: center right;
      top: auto;
      bottom: 0;
    }
    @include media("<=480px") {
      right: -20rem;
      width: 150%;
    }
  }

  .bear-bull-cliffleft {
    background-image: url("../../../public/assets/bear-bull-game/landing-cliffleft.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
    @include media("<=950px") {
      left: -25rem;
      background-size: cover;
      background-position: center left;
      top: auto;
      bottom: 0;
    }
    @include media("<=480px") {
      left: -20rem;
      width: 150%;
      bottom: 0;
    }
  }

  // Characters
  .landing-bearking-idle,
  .landing-bullking-idle {
    position: absolute;
    z-index: 20;
    object-fit: cover;
    cursor: $cursor-Pointer;
  }

  .bear-king-wrapper {
    position: absolute;
    bottom: 15%;
    left: 17.5%;
    z-index: 20;
    width: 543px;
    height: 615px;
    &:hover {
      .gray-lock {
        opacity: 1;
        transform: translate(-50%, -300px);
        @include media("<=1450px") {
          transform: translate(-50%, -250px);
        }
        @include media("<=480px") {
          transform: translate(-50%, -175px);
        }
      }
    }

    .gray-lock {
      position: absolute;
      width: 50px;
      left: 50%;
      transform: translate(-50%, -200px);
      opacity: 0;
    }
    @include media("<=1920px") {
      bottom: 14.5%;
      left: 12.5%;
      width: 500px;
      height: 570px;
    }
    @include media("<=1600px") {
      bottom: 19.5%;
      left: 2%;
      width: 500px;
      height: 570px;
    }
    @include media("<=1440px") {
      bottom: 16.5%;
      left: 12.5%;
      width: 400px;
      height: 456px;
    }
    @include media("<=1200px") {
      left: 7.5%;
    }
    @include media("<=950px") {
      left: -5.5%;
    }
    @include media("<=480px") {
      bottom: 22%;
      left: -17.5%;
      width: 250px;
      height: 285px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .bull-king-wrapper {
    position: absolute;
    bottom: 14.5%;
    right: 12.5%;
    z-index: 20;
    width: 489px;
    height: 554px;
    @include media("<=1920px") {
      bottom: 14.5%;
      right: 7%;
      width: 450px;
      height: 510px;
    }

    @include media("<=1600px") {
      bottom: 19.5%;
      right: -2%;
      width: 450px;
      height: 510px;
    }
    @include media("<=1440px") {
      bottom: 16.5%;
      right: 8%;
      width: 360px;
      height: 408px;
    }
    @include media("<=1200px") {
      right: 1%;
    }
    @include media("<=950px") {
      right: -16.5%;
    }
    @include media("<=480px") {
      right: -25.5%;
      bottom: 22%;

      width: 225px;
      height: 255px;
    }
    &:hover {
      .gray-lock {
        opacity: 1;
        transform: translate(-50%, -100px);
      }
    }

    .gray-lock {
      position: absolute;
      width: 50px;
      left: 35%;
      transform: translate(-50%, -50px);
      opacity: 0;
    }
    .landing-bullking-idle {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: $cursor-Pointer;
    }
  }

  .bottom-header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 30;
    padding: 0 2rem;
    transform: translateY(1px);
    .bear-bull-game-logo {
      padding: 1rem;
      font-family: $font-superStar;
      font-size: 36px;
      position: relative;
      color: white;
      transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      @include media("<=1200px") {
        font-size: 32px;
      }
      @include media("<=950px") {
        display: none;
      }
      span {
        position: absolute;
        color: black;
        -webkit-text-stroke: 1px white;
        left: 5.25rem;
        top: 2rem;
        font-size: 28px;
        @include media("<=1200px") {
          top: 1.5rem;
          left: 4.9rem;
        }
      }
    }

    .announcement-wrapper {
      cursor: $cursor-Pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      background-color: #000;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      border-top: 1px solid rgb(159, 155, 155);
      height: 4rem;
      bottom: 0;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 20%;
        bottom: 0;
        left: 0;
        background-color: black;
        z-index: 2;
      }
      @include media("<=950px") {
        left: auto;
        gap: 0.25rem;
        right: 10%;
        transform: translateX(0%);
        line-height: 1;
        text-align: center;
      }
      @include media("<=480px") {
        right: auto;
        left: 0;
        width: 100%;
      }
      &.empty-announcement {
        top: 2rem;
        border: none;
        background-color: transparent;
      }

      .announcement-img {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.5rem;
      }

      .announcement-curve {
        transform: scaleY(3) scaleX(2);
      }
      .announcement-coin-gif {
        width: 100px;
        height: 100px;
        position: relative;
        top: -2rem;
      }

      .announcement-text {
        font-size: 28px;
        color: white;
        font-family: $font-superStar;
        position: relative;

        @include media("<=1350px") {
          font-size: 28px;
        }
        @include media("<=1250px") {
          font-size: 24px;
        }
      }

      .announcement-curve {
        position: absolute;
        width: 6rem;
        height: 6rem;

        path:first-child {
          fill: black;
        }
        path:nth-child(2) {
          stroke: rgb(159, 155, 155);
          stroke-width: 1px;
        }
        &.right {
          top: -0.655rem;
          right: -5.86rem;
          transform: rotate(180deg) scaleX(-1);
        }
        &.left {
          top: -0.655rem;
          left: -5.8rem;
          transform: rotate(180deg);
        }
      }
    }

    .next-section-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      outline: none;
      border-bottom: none;
      border-left: none;
      border-right: none;
      position: relative;
      top: 17.5px;
      cursor: $cursor-Pointer;
      animation: fadeOut 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
      animation-delay: 1.5s;
      transform: translateY(100%);
      svg {
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      @keyframes fadeOut {
        0% {
          transform: translateY(100%);
        }
        100% {
          transform: translateY(0%);
        }
      }
      @include media("<=480px") {
        display: none;
      }
      &:hover,
      &:focus {
        svg {
          filter: brightness(2);
        }
      }
    }
  }
}

.scroll-to-top {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  outline: none;
  border: none;
  bottom: -0.25rem;
  z-index: 19;
  cursor: $cursor-Pointer;
  @include media("<=480px") {
  }

  svg {
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &:hover,
  &:focus {
    svg {
      filter: brightness(2);
    }
  }
}

.initial-loading-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #151515;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  pointer-events: none;
  user-select: none;
  img {
    width: 250px;
  }
  &.hide {
    opacity: 0;
  }
}
